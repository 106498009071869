.carousel {
  width: 100%;
  height: 500px;
}

.carousel__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.carousel__photo {
  position: relative;
  width: 100%;
  height: 500px;
  z-index: 1;
  object-fit: cover;
}


.carousel__indicators {
  position: absolute;
  display: flex;
  align-self: center;
  gap: 8px;
  padding: 0 0 28px 25px;
  z-index: 5;
  margin-top: 450px;
}

.carousel__indicator {
  width: 13px;
  height: 13px;
  background-color: white;
  border: 1px solid gray;
  z-index: 5;
  align-self: flex-end;
  display: flex;
}


.carousel__indicator-active {
  background-color: var(--text-color);
  width: 14px;
  height: 14px;
  border: 1px solid black;
  z-index: 5;
}



.carousel__btns {
  align-self: flex-end;
  display: flex;
  gap: 1px;
  padding: 1px;

}

.carousel__btn {
  width: 50px;
  height: 50px;
  border: none;
  background-color: white;
  z-index: 4;

}

  
.carousel__title {
  position: absolute;
  margin: 0;
  margin-top: 160px;
  padding: 20px;
  border: 2px solid white;
  color: white;
  background-color: rgba(41, 50, 65, 0.3);
  font-size: 50px;
  max-width: 600px;
  width: 100%;
  z-index: 4;
  text-align: center;
}   


@media (max-width: 768px) {
  .carousel__title {
      font-size: 36px;
      max-width: 425px;
      width: 100%;
  }
}
@media (max-width: 483px) {
  .carousel__title {
      font-size: 26px;
      max-width: 265px;
      width: 100%;
  }
}
