.page__promo {
    max-width: 1280px;
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    background-position: center bottom;
    display: flex;
    justify-content: center;

}

.promo__title {
    color: white;
    font-size: 66px;
    max-width: 625px;
    width: 100%;
}
