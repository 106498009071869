.burger {
  display: none;
  list-style: none;
}

.burger-second-line {
  display: none;
}

.burger-first-line {
  transform: rotate(-45deg) translate(-6px, 7px);
}

.burger-third-line {
  transform: rotate(45deg) translate(-6px, -8px);
}
@media screen and (max-width: 768px) {
  .burger {
    position: relative;
    cursor: pointer;
    display: block;
    width: 20px;
    min-height: 22px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    margin-right: 6px;

  }


  .burger-line {
     position: absolute;
     width: 100%;
     height: 3px;
     background-color: var(--backlight-color);
     margin-top: 3px;
     transition: all 0.5s ease-out;
     z-index: 20;
    margin-left: 3px;
  }

  .burger-line:first-child {
     top: -1px;
  }

  .burger-line:nth-child(2) {
     top: 8px
  }

  .burger-line:last-child {
     bottom: -2px;
  }
}
@media screen and (max-width: 320px) {
  .burger {
   margin-bottom: 15px;
   margin-right: 7px;
  }
}