.popup__close {
    position: absolute;
    background-image: url('../../img/CloseIcon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 32px;
    width: 100%;
    border: none;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .001);
    padding: 0;
    height: 32px;
    margin: 0;
    right: -40px;
    top: -40px;
    transition: .5s ease-in-out;
  }
  .popup__close:hover {
    opacity: 0.6;
  }
  @media screen and (max-width: 767px) {
      .popup__close {
          width: 22px;
          height: 22px;
          right: 0px;
        }
  }
  .popup__container {
    background-color: #FFFFFF;
    border-radius: 10px;
    color: #000;
    max-width: 430px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    padding: 34px 36px 35px 36px;
    row-gap: 49px;
  
  
  }
  @media screen and (max-width: 767px) {
      .popup__container {
          width: calc(282px + (430 - 282) * ((100vw - 320px)/(1280 - 320)));
          padding: 25px 22px;
          row-gap: 40px;
        }
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: visibility .5s, opacity .5s ease-in-out;
    overflow: hidden;
    z-index: 5;
  }
  .popup_opened {
    visibility: visible;
    opacity: 1;
  }
  .popup__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .popup__links {
    text-decoration: none;
    color: #000;
    font-size: 24px;
    transition: font-size 0.3s;
  }
  .popup__links:hover {
    text-decoration: none;
    color: rgb(74, 74, 212);
    font-size: 28px; 
  }