.youtube {
    background-color: var(--fon-color);
    padding-bottom: 50px;
    color: var(--grey-color);
}

.youtube__block {
display: flex;
justify-content: space-between;
align-items: flex-start;

}
.youtube__link {
    color: var(--grey-color);
 
}

@media (max-width: 630px) {
    .youtube__block {
         flex-direction: column;
justify-content: center;
align-items: center;
gap: 20px;

    }
}
@media (max-width: 630px) {
    .youtube__block-video {
        max-width: 520px;
        width: 100%;
    }
}