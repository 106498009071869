.advantage {
    background-color: var(--fon-color);
}
.advantage__container {

    padding-bottom: 50px;
}
.advantage__grid {
    display: grid;
    grid-template-columns: repeat(3, 250px);
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.advantage__imgs {
    max-width: 50px;
    width: 100%;

}
.advantage__grid_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
.advantage__text,
.advantage__title{
    color: var(--grey-color);
    padding-left: 10px;
}
@media (max-width: 871px) { 
    .advantage__grid {
        grid-template-columns: repeat(2, 250px);
    }
}
@media (max-width: 594px) { 
    .advantage__grid {
        grid-template-columns: repeat(2, 150px);
    }
}