.page__header {
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: center;


}
.header__container{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  align-items: center;
  padding: 7px 60px 7px 60px;
  box-sizing: border-box;
  background-color: var(--backdark-color);

}
.logo {
  display: block;
  width: 121px;
  background-position: center;
  height: 64px;
  background-image: url("../../img/logo3.png");
  transition: opacity .3s linear;
  background-size: contain;
  background-repeat: no-repeat;

}
.header__button-click{
  background-color: var(--text-color);
  cursor: pointer;
  border: none;
  padding: 7px 7px 7px 7px;
  border-radius: 10px;
  transition: transform 0.3s, filter 0.3s ease-in-out;
}
.header__button-click:hover{
  transform: scale(1.1);
  filter: brightness(120%)
}
.header__lists {
  display: flex;
  justify-content: space-between;
  list-style: none;
  text-decoration: none;
  gap: 58px;
  margin: 0;
  
}
.header__link { 
  text-decoration: none;
  font-size: 16px;
  line-height: 35px;
  color: var(--text-color);
  font-weight: 500;
  margin: 0;

}
.header__language {
  position: relative;
  width: 50px;
  padding-left: 120px;
}

select {
  border: none; 
  outline: none;
  width: 50px;
  height: 50px; 
}

.header__links {
  transition: transform 0.3s, filter 0.3s; /* Добавляем плавную анимацию для увеличения и изменения цвета */
  color: var(--text-color);
  margin: 0;
}

.header__links:hover {
  transform: scale(1.2); /* Увеличиваем размер изображения */
  filter: brightness(110%); /* Подсвечиваем изображение */
  
}
.header__link:hover {
  color: #EF7E18;
}
.header__links-item {
  margin: 0;
  text-decoration: none;
  color: var(--text-color);
  font-size: 14px;
}

@media (max-width: 768px) {
  .header__container {

    padding: 10px 20px 10px 20px;
  }

  .header__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 33%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9;
  }

  .header__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9;
    transform: translateX(100%);
    transition: opacity 3s ease-in-out;
  }

  .header__overlay-active {
    background-color: rgba(0, 0, 0, 0.3);
    transform: translateX(-0%);
  }

  .header__navigation {
    display: flex;
    justify-content: flex-end;
    position: relative;
    /* width: calc(320px + (670 - 320) * ((100vw - 320px)/(768 - 320))); */
    order: 3;
  }

  .header__lists {
    position: fixed;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    line-height: 22px;
    opacity: 0;
    background-color: white;
    text-align: center;
    top: 0;
    left: 67%;
    bottom: 0;
    max-width: 532px;
    width: 100%;
    height: 100vh;
    z-index: 10;
    transform: translateX(50%);
    transition: transform 0.7s ease;
    padding: 0;
  }

  .header__link {
    font-size: 18px;
  }


  .header__lists_active {
    opacity: 1;
    transform: translateX(-50%);
  }


}

