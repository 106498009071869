

.zabors__grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 40px;
}

.zabors__grid_item {
    max-width: 350px;
    width: 100%;
    height: 300px;
    position: relative;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}
.zabors__grid_item:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 10px var(--text-color);
}

.zabors__imgs {
    background-size: cover;
    max-width: 400px;
    height: 300px;
    background-repeat: no-repeat;
    
}

.zabors__title {
    font-size: 24px;
    text-align: center;
    bottom: 0px;
    color: var(--grey-color);
    background-color: rgba(41, 50, 65, 0.87);
    z-index: 0;
    position: absolute;
    padding: 24px 20px 20px;
    left: 20px;
    right: 20px;
}

.zabors__img1 {
    background-image: url("./../../img/profMain.jpg");

}

.zabors__img2 {
    background-image: url("./../../img/main2.jpg");
}

.zabors__img3 {
    background-image: url("./../../img/main3.jpg");
}

.zabors__img4 {
    background-image: url("./../../img/main4.jpg");
}

.zabors__img5 {
    background-image: url("./../../img/main5.jpg");
}

.zabors__img6 {
    background-image: url("./../../img/main7.jpg");
}

.zabors__img7 {
    background-image: url("./../../img/main6.jpg");
}

.zabors__img8 {
    background-image: url("./../../img/main8.jpg");
}