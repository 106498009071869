.footer__container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    align-items: center;
    padding: 7px 60px 7px 60px;
    box-sizing: border-box;
    background-color: var(--backdark-color);
}
.footer__list {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    list-style: none;
}
@media (max-width: 768px) {
    .footer__container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer__list {
        padding: 0px;
    }
}