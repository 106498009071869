.slider {
    width: 100%;
}

.slider__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

.slider__photo {
    position: relative;
    display: flex;
justify-content: center;
align-items: center;
   gap: 20px;
    width: 100%;
    z-index: 1;

}

.slider__photo-left,.slider__photo-right {
    object-fit: cover;
    height: auto;
    max-width: 300px;
    width: 100%;
    box-shadow: 0px 5px 5px var(--fon-color);

    z-index: 1;
}


.slider__photo-center {
    object-fit: cover;
    height: auto;
    max-width: 500px;
    width: 100%;
    height: 354px;
    box-shadow: 0px 12px 15px 10px var(--backlight-color);
}


.slider__btns {
    align-self: flex-end;
    display: flex;
    gap: 15px;
    padding: 1px;

}

.slider__btn {
    width: 50px;
    height: 50px;
    border: none;
    background-color: white;

}

.slider__btn-left {
    background-image: url('../../img/Vector.png');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
}

.slider__btn-right {
    background-image: url('../../img/Vector.png');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
    rotate: 180deg;
}



@media (max-width: 1147px) { 
    .slider__photo-size  {
width: 60%;
    }
}

@media (max-width: 720px) { 
    .slider__photo-center {
height: 250px;
    }
    .slider__btns {
        margin-top: 20px;
        align-self: center;
}
}
@media (max-width: 520px) { 
    .slider__photo-center {
height: 200px;
    }
}