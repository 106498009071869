.contacts {
    background-color: var(--fon-color);
    color: var(--grey-color);
}

.contacts__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 40px;
    
    
}

.contacts__list {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    font-size: 24px;
    /* padding-top: 20px; */
    gap: 20px;
    padding-left: 20px;
    padding-bottom: 10px;

}

.contacts__links {

    text-decoration: none;
    color: var(--grey-color);
    transition: transform 0.3s ease-in-out;
}

.contacts__links:hover {
    text-decoration: none;
    color: var(--text-color);
    transform: scale(1.1);
}

.contacts__img {
    max-width: 300px;
}

.contacts__map {
    max-width: 600px;
    width: 100%;
    height: 400px;
    box-shadow: 1px 1px 10px var(--text-color);
}

@media (max-width: 1000px) {

    .contacts__container {
        justify-content: center;
        align-items: center;
    }

    .contacts__list {
        justify-content: center;
        align-items: center;
    }

    .contacts__links {
        text-align: center;
    }
}